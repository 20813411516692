<template>
  <sub-page
      v-model="$store.state.discount.pageData"
      :title="this.$t('Discounts') "
      icon="mdi-sale"
      :header-action-text="'+ ' + $t('discount.create')"
      :header-action="openAddDiscount"

  >

    <template #default>

      <ws-data-table
          class="mt-5"
          :items="items"
          :headers="headers"
          :row-action="openEditDiscount"
      >
        <template #item.name="{item}">
          <h5> {{ item.name }}</h5>
        </template>

        <template #item.percent="{item}">
          <h4>{{ item.percent }} %</h4>
        </template>

        <template #item.range="{item}">
          <h5> {{ $t(`discount.${item.range}.title`) }}</h5>
        </template>

        <template #item.date_start="{item}">
          <h5 v-if="!item.date_start"> {{ $t(`NoRestrictions`) }}</h5>
          <h5 v-else>
            <v-icon :color="wsACCENT">mdi-calendar</v-icon>
            {{ PARSE_DATE(item.date_start , false, true)}}
          </h5>
        </template>

        <template #item.date_end="{item}">
          <h5 v-if="!item.date_end"> {{ $t(`NoRestrictions`) }}</h5>
          <h5 v-else>
            <v-icon :color="wsACCENT">mdi-calendar</v-icon>
            {{ PARSE_DATE(item.date_end , false, true)}}
          </h5>
        </template>

        <template #item.is_active="{item}">
          <ft-select
              :items="CHOICE_ACTIVE"
              @input="editActiveStatus(item,$event)"
          >
            <v-chip dark :color="item.is_active ? wsSUCCESS : 'grey'">
              <h5 class="text-no-wrap pl-2">
                {{ item.is_active ? $t('Active') : $t('NotActive') }}
                <v-icon>mdi-chevron-down</v-icon>
              </h5>
            </v-chip>
          </ft-select>

        </template>

        <template #item.params="{item}">
          <div v-if="item.range === 'course'">
            <span v-if="item.courses_names.length === 1 ">
              <h5>{{ item.courses_names[0] }}</h5>
            </span>
            <span v-else>
                 <h5 class="d-flex align-center">{{ item.courses_names[0] }}  <v-chip class="ml-2" outlined :color="wsACCENT">+ {{ item.courses_names.length - 1 }}</v-chip> </h5>
            </span>
          </div>
          <div v-if="item.type === 'subscription'">
            <h5> {{ $t('Period') }} : {{ item.period }}   </h5>
          </div>

        </template>

        <template #item.action="{item}">
          <v-btn @click.stop="openDelete(item.uuid)" :color="wsACCENT"  icon>
            <v-icon>mdi-delete</v-icon>
          </v-btn>
        </template>


      </ws-data-table>
    </template>

    <template #dialog>
      <ws-dialog
          @save="saveDiscount"
          @delete="deleteDiscount"
          v-if="displayDialog"
          v-model="displayDialog"
          :title="$t('discount.create')"
          :show-delete="!newDiscount"
      >
        <ws-text-field
            v-model="entityData.name"
            :label="$t('Name')"
            :placeholder="$t('EnterName')"
        />
        <ws-select
            class="mt-5"
            v-model="entityData.range"
            :items="rangeTypes"
            :label="$t('discount.range.choose')"
            :placeholder="$t('discount.range.title')"
        />
        <v-expand-transition>
          <ws-select
              v-if="entityData.range === 'course'"
              class="mt-5"
              v-model="entityData.courses"
              :items="coursesSelect"
              :label="$t('Course_')"
              :placeholder="$t('ChooseCourse')"
              multiple
          />
        </v-expand-transition>

        <ws-select
            class="mt-5"
            v-model="entityData.type"
            :items="discountTypesFiltered"
            :label="$t('Type')"
            :placeholder="$t('discount.type.choose')"
        />

        <v-expand-transition>
          <ws-select
              v-if="entityData.type === 'subscription'"
              class="mt-5"
              v-model="entityData.period"
              :items="subscriptionPeriodsSelect"
              :label="$t('SubscriptionPeriod')"
              :placeholder="$t('discount.subscription_period.choose')"
          />
        </v-expand-transition>

        <a-parameter-slider
            class="mt-2"
            v-model="entityData.percent"
            :label="$t('Percent')"
            max="100"
            min="1"
        >

        </a-parameter-slider>

        <ws-switch class="mt-6" v-model="entityData.is_active" :placeholder="entityData.is_active ? $t('Active') : $t('NotActive')" >
        </ws-switch>

      </ws-dialog>
      <ws-dialog
          :title="$t('DeleteDiscount')"
          v-model="displayDelete"
          @delete="deleteDiscount(selectedDiscountUuid)"
          display-confirm-delete
          close-delete
      />
    </template>

  </sub-page>
</template>

<script>
import { mapActions } from "vuex";

export default {
  name: "permanentDiscount",
  data() {
    return  {
      items : [],
      entityData : {
        courses : []
      },
      displayDialog : false,
      newDiscount : false,
      coursesSelect : [],
      displayDelete : false,
      selectedDiscountUuid : null
    }
  },
  computed : {
    subscriptionPeriodsSelect() {
      let items = []

      if (this.entityData.range !== 'course') {
        for (let i = 1; i <= 24; i++) {
          items.push({text: i, value: i})
        }
      } else {
        if ( Array.isArray(this.entityData.courses) && this.entityData.courses.length === 1 ) {
          let course = this.coursesSelect.find(el => el.value === this.entityData.courses[0])
          if ( course ) {
            course.time_periods_select.forEach(period => {
              items.push({text: period, value: period})
            })
          }
        }

      }

      return items
    },
    rangeTypes() {
      return [
        { text : this.$t('discount.global.title') , value : 'global' },
        { text : this.$t('Course_') , value : 'course' },
      ]
    },
    discountTypes() {
      return [
        { text : this.$t('Default') , value : 'default' },
        { text : this.$t('Subscription') , value : 'subscription' },
      ]
    },
    discountTypesFiltered() {
      let items = JSON.parse(JSON.stringify(this.discountTypes))

      if ( this.entityData.range === 'course' ) {
        items.splice(1,1)

        if (this.coursesSelect.length > 0 ) {

          if ( Array.isArray(this.entityData.courses) && this.entityData.courses.length === 1 ) {
            let course = this.coursesSelect.find(el => el.value === this.entityData.courses[0])
            if ( course ) {
              if ( course.is_subscription) {
                items.push(  { text : this.$t('Subscription') , value : 'subscription' })
              }
            }
          }

        }

      }
      return items
    },
    headers() {
      return [
        { text : this.$t('Name')        , value : 'name' },
        { text : this.$t('Percent')     , value : 'percent' },
        { text : this.$t('Settings') , value : 'params' },
        { text : this.$t('Active')      , value : 'is_active' },
        { value : 'action' }
      ]
    },
  },
  methods : {
    ...mapActions('discount' , [
        'GET_BUSINESS_DISCOUNTS',
        'ADD_EDIT_DISCOUNT',
        'DELETE_DISCOUNT'
    ]),

    openDelete(uuid) {
      this.selectedDiscountUuid = uuid
      this.displayDelete = true
    },

    editActiveStatus(item , status) {
      this.newDiscount = false
      this.entityData = {
        uuid : item.uuid,
        is_active : status
      }
      this.saveDiscount()
    },
    quickDeleteDiscount() {

    },
    async saveDiscount() {
      let result = await this.ADD_EDIT_DISCOUNT(this.entityData)
      if ( !result ) {
        return
      }
      if ( this.newDiscount ) {
        this.items.push(result)
      } else {
        let index = this.items.findIndex(el => el.uuid === result.uuid )
        if ( index === -1 ) {
          return
        }
        this.$set(this.items, index , result)
      }

      this.displayDialog = false
    },
    async deleteDiscount(uuid = false) {
      let discountId = !uuid ? this.entityData.uuid : uuid
      let result = await this.DELETE_DISCOUNT(discountId)
      if ( !result ) {
        return
      }
      let index = this.items.findIndex(el => el.uuid === discountId )
      if ( index === -1 ) {
        return
      }
      this.items.splice(index,1)
      this.displayDialog = false
      this.notify(this.$t('DiscountDeleted'))
      this.displayDelete = false
    },
    openAddDiscount() {
      this.entityData = {
        type : 'default',
        percent : 10,
        range : 'global'
      }
      this.newDiscount = true
      this.displayDialog = true
    },
    openEditDiscount(item) {
      this.entityData = Object.assign( {} , item )
      this.newDiscount = false
      this.displayDialog = true
    }
  },
  async mounted() {
    let result = await this.GET_BUSINESS_DISCOUNTS()
    if ( !result ) {
      return
    }
    this.items = result.discounts
    this.coursesSelect = result.courses
  }


}
</script>

<style scoped>

</style>